//
// style for profil matching component
//


// main content
.match {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 0 auto;

	@media print,
	screen and (min-width: 40em) {
		width: 150px;
	}

	//show a hide text 
	&:hover {
		.match__toptext {
			opacity: 1;
			transform: translate(-50%, -100%);
		}
	}
}

.matchLocked {
	color: #737373;
	background-color: #e6e6e6;
}

// parent of svg component
.match__progress {
	position: relative;
	text-align: center;
	line-height: 1;
}

// percent text, center via position and transform
.match__percent {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 1.2rem;
	line-height: 1;
	font-weight: 600;

	.unit {
		font-size: .75rem;
		font-weight: lighter;
		margin-top: .25rem;
	}
}

.lockedMatching {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: auto;
	@media print, screen and (min-width: 40em)  {
        width: 7rem;
    }
}

.lockedMatchingText {
	font-size: .9rem;
	text-align: center;
	line-height: 1rem;
}

// hide text
.match__toptext {
	display: none;

	@media print,
	screen and (min-width: 40em) {
		display: block;
		position: absolute;
		top: 0%;
		left: 50%;
		transform: translate(-50%, 0%);
		width: 6.25rem;
		padding: .1875rem;
		font-size: .75rem;
		line-height: 1.2;
		text-align: center;
		background-color: #f0f3f6;
		opacity: 0;
		transition: .3s all cubic-bezier(.455, .03, .515, .955);
		z-index: 10;
	}
}

// bottom text
.match__bottomtext {
	margin-top: .3125rem;
	font-size: .75rem;
	text-align: center;
}

.blur {
	display: inline-block;
	filter: blur(6px);
}