.jobSubheader {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem 0 0 0;
    font-size: .875rem;

    @media print, screen and (min-width: 64em) {
        margin: 0;
        font-size: 1rem;
    }

    span {
        line-height: 1.2;
    }
}

.jobHeaderItem {
    display: flex;
    flex-direction: column;
    margin: .5rem 0;

    @media print, screen and (min-width: 40em) {
        flex-direction: row;
    }

    & > span {
        margin:0 0 .75rem 0;
        display: flex;
        align-items: center;
        @media print, screen and (min-width: 40em) {
            margin:0 .5rem 0 0;
        }

        &:last-child {
            margin: 0;
        }
    }
}

.employerNameIcon {
    flex: 0 0 .875rem;
    max-width: .875rem;
    margin-right: .3rem;
}

.employerName {
    max-width: 25rem;
}

.locationIcon {
    flex: 0 0 1.25rem;
    max-width: 1.25rem;
    margin: 0 0 0 -2px;
    @media print, screen and (min-width: 40em) {
        margin: 0;
    }
}