//
// style for jobs list
//

// main list of jobs
.joblist {
    margin: 0;
    padding: 0;
    list-style: none;

    &.layout-small {
        margin-bottom: 1rem;

        @media print,
        screen and (max-width: 63.99875em) {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 2vw;
                height: 100%;
                background-color: #df1051;
            }

            width: calc(100% + 10vw);
            margin: 0 0 1rem -5vw;
            padding: 0 4vw 0 2vw;
        }
    }

    // special border to articles details only mobile
    [class="ce--frame-sticky"] & {
        &.layout-small {
            width: calc(100% + 10vw);
            margin: 0 0 1rem -5vw;
            padding: 0 4vw 0 5vw;

            @media print,
            screen and (min-width: 40em) {
                &:before {
                    display: none;
                }
                width: 100%;
                margin: 0 0 1rem 0;
                padding: 0;
            }
        }
    }
}

// content item
.jobitem__show,
.jobitem__hidden {
    position: relative;
    padding: 1rem 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    opacity: 0;
    transform: translate(0, 1rem);
    transition: .5s all cubic-bezier(.455, .03, .515, .955);

    @media print,
    screen and (min-width: 40em) {
        padding: 1rem 2rem;
    }

    &.jobitem__tops {
        border-top: 0;
        border-bottom-style: dashed;
        background-color: transparent;

        &:first-of-type {
            border-top: 3px solid #000;
        }

        &:last-of-type {
            margin: 0 0 3rem 0;
            border-bottom: 3px solid #000;
        }

        &:before {
            border-color: #000;
        }

        &[class*="hover"],
        &:hover {
            background-color: #fefefe;

            &:before {
                border-width: 5px;
            }
        }
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 0px solid #f0f3f6;
        transition: .3s border cubic-bezier(.455, .03, .515, .955);
        z-index: -1;
    }

    // active animate
    &[class*="hover"],
    &:hover {
        border-color: #f0f3f6;

        &:before {
            border-width: 10px;
        }

        .jobitem__title {
            text-decoration: underline;
        }
        .jobitem__share,
        .jobitem__social {
            right: 0;
            opacity: 1;
            visibility: visible;
        }
    }
}

.jobitem__show {
    opacity: 1;
    transform: translate(0, 0);
}

// social icons on the left
$social-desktop-width: 5rem;
$social-mobile-width: 3rem;

.jobitem__share,
.jobitem__social {
    display: flex;

    @media print,
    screen and (min-width: 40em) {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: $social-desktop-width;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 0;
        opacity: 1;
        visibility: visible;
        transition: .3s all cubic-bezier(.455, .03, .515, .955);
    }
    @media print,
    screen and (min-width: 64em){
        right: -2rem;
        opacity: 0;
        visibility: collapse;
        background-color: #f0f3f6;
    }


    a {
        padding: 1rem 0;
    }
}

.jobitem__share {
    @media print,
    screen and (min-width: 64em) {
        justify-content: center;
    }
}

.jobitem__content {
    display: flex;
    flex-direction: column;
    padding-right: 0;
    text-decoration: none;
    color: #000;

    // disable global text-decoration
    &:hover {
        text-decoration: none;
    }

    @media print,
    screen and (min-width: 40em) {
        flex-direction: row;
        align-items: center;
    }

    @media print,
    screen and (min-width: 64em) {
        height: 100px;
    }

    .jobitem__tops & {
        display: block;
        height: auto;
        padding-right: 0;
    }
}

// style company logo
.jobitem__logo {
    margin: .5rem 0 1rem;

    @media print,
    screen and (min-width: 40em) {
        display: flex;
        flex: 0 0 100px;
        height: auto;
        margin: 0 4vw 0 0;
    }
    @media print,
    screen and (min-width: 64em) {
        flex: 0 0 140px;
    }

    .jobitem__tops & {
        display: block;
        margin: 0 0 1rem;
    }

    img {
        .jobitem__tops & {
            max-width: 120px;
            width: 100%;
            max-height: 60px;
            height: 100%;
            object-fit: contain;
            object-position: left;
        }
    }
}

.jobitem__lazylogo {

    @media print,
    screen and (max-width: 39.69em) {
        img {
            max-height: 60px;
            width: auto;
            max-width: 50%;
        }
    }

    @media print,
    screen and (min-width: 40em) {
        display: flex !important;
        align-items: center;
        img {
            object-fit: contain;
            max-width: none;
            width: 100%;
            max-height: 50px;
        }
    }
    @media print,
    screen and (min-width: 64em) {
        img {
            width: 100%;
            max-height: 60px;
        }
    }
}

.jobitem__info {
    flex: 1;
}

.jobitem__title {
    display: inline-block;
    margin: 0 0 .75rem 0;
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: bold;
    text-decoration: none;
    color: #000;
    transition: border-color .3s cubic-bezier(.23, 1, .32, 1);
    hyphens: auto;

    @media print,
    screen and (min-width: 40em) {
        margin: 0 0 .25rem 0;
    }
}

.jobitem__sharetext {
    display: none;
    margin: 0 0 0.5rem 0;
    font-size: .75rem;
    text-align: center;
    @media print,
    screen and (min-width: 64em) {
        display: block;
    }
}

.locationIcon {
    width: 1.25rem!important;
    height: 1.25rem!important;
    margin: 0 0 0 -2px!important;
    @media print, screen and (min-width: 40em) {
        margin: 0!important;
    }
}

.employerNameIcon {
    margin-right: .3rem!important;
}

.job_expires {
    position: relative;
    display: inline-block;
    color: #df1051;
    font-size: medium;
    font-weight: 600;
    transform: translate(0,0);
    transition: .3s transform cubic-bezier(.455, .03, .515, .955);
    margin-bottom: 1rem;

    @media print, screen and (min-width: 40em) {
        margin-bottom: 0;
    }
}