.item {
    position: relative;
    padding: 1.5vh 0 1vh 0;
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
    overflow: hidden;
    cursor: pointer;
    transition: .3s all cubic-bezier(.455, .03, .515, .955);

    @media print, screen and (min-width: 64em) {
        padding: 1.5vh 1vh;
    }

    *[class*="ce--layout-bg-highlight-color"] & {
        border-top-color: var(--highlight-invert-bw-color)!important;
    }

    *[class*="ce--layout-bg-color-dark"] & {
        border-top-color: #fff!important;
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 0px solid #000;
        transition: .3s border cubic-bezier(.455, .03, .515, .955);
        z-index: -1;
    }

    &:last-child {
        padding-bottom: 0;

        *[class*="ce--layout-bg-highlight-color"] & {
            border-bottom-color: var(--highlight-invert-bw-color)!important;
        }

        *[class*="ce--layout-bg-color-dark"] & {
            border-bottom-color: #fff!important;
        }
    }

    &:hover {

        &:before {
            border-bottom-width: 5px;
            border-top-width: 5px;
            @media print, screen and (min-width: 64em) {
                border-width: 5px;
            }
        }

        .item__title {
            text-decoration: underline;
        }
    }

    svg {
        width: 1rem;
        height: 1rem;
    }

    .subheader {
        font-size: .875rem;
    }
}

/*! purgecss start ignore */
.item__title--layout-default {
    font-size: .875rem;
}

.item__title--layout-big {
    font-size: 1.25rem;
}
/*! purgecss end ignore */

.item__content {
    display: flex;
    flex-direction: column;
    padding: .25rem .25rem .25rem .75rem;
    text-decoration: none;
    color: #000;
    // disable global text-decoration
    @media print, screen and (min-width: 40em) {
        padding: .25rem .25rem .25rem 1.75rem;
    }
    @media print, screen and (min-width: 64em) {
        padding: .25rem;
    }

    *[class*="ce--layout-bg-highlight-color"] & {
        color: var(--highlight-invert-bw-color)!important;
    }

    *[class*="ce--layout-bg-color-dark"] & {
        color: #fff!important;
    }

    &:hover {
        text-decoration: none;
    }
}